import React from 'react';

export default class Terms extends React.Component {
  render() {
    return(
      <div className="common-container">
      <p className="s1">Términos y Condiciones</p>
      <p className="s2">La aceptación de los presentes Términos y Condiciones crea un Contrato de Servicios de Almacenamiento (el “Contrato”) entre usted como <span>el “Cliente”, y Boxmi, S.A.P.I. de C.V. (“Boxmi” y conjuntamente con el Cliente, las “Partes”), por lo que, si usted no acepta estos Términos y Condiciones, usted debe de abstenerse de utilizar los Servicios (según se definen más adelante). Al utilizar los Servicios usted manifiesta su consentimiento total con este Contrato; el cual se sujeta al tenor de las siguientes Cláusulas:</span></p>
      <p><br/></p>
      <p className="s3">PRIMERA. DEFINICIONES.</p>
      <p>Los conceptos que inicien en mayúsculas tendrán los significados atribuidos a éstos en el contenido de este Contrato y de acuerdo a las siguientes definiciones:</p>
      <p><br/></p>
      <p>“Artículos” significa todos y cada uno de los artículos que por solicitud del Cliente serán almacenados por Boxmi en el Sitio, y de los cuales se elaborará el inventario al que se hace referencia en la Cláusula Segunda, Sección 2.2 Subsección (d) del presente Contrato, el cual estará a disposición del Cliente en todo momento a través de la Plataforma. Así mismo, el Cliente podrá solicitar a Boxmi actualizaciones al inventario de los Artículos directamente a través de la Plataforma, o bien mediante comunicación a través de los medios acordados en la Cláusula Octava de este Contrato (en ambos casos, sujeto a la aceptación por parte de Boxmi, a entera discreción de este último).</p>
      <p><br/></p>
      <p>“Aviso de Privacidad” significa el aviso de privacidad de Boxmi, el cual se puede consultar en la Plataforma. “Boxmi” se refiere a la sociedad mercantil denominada Boxmi, S.A.P.I. de C.V.</p>
      <p>“Caja Sellada” significa aquella caja que se entrega por el Cliente a Boxmi completamente sellada y con mecanismos de seguridad suficientes para evitar el acceso no autorizado al contenido de la misma, incluyendo aquel que pudiera tener Boxmi o cualquiera de sus representantes y/o empleados.</p>
      <p><br/></p>
      <p>&quot;Caja Boxmi&quot; se refiere a la caja suministrada por Boxmi, en calidad de préstamo, con el propósito de que el Cliente pueda embalar sus Artículos. En este acto, las Partes reconocen que las cajas son propiedad exclusiva de Boxmi y deberán ser restituidas por el Cliente al concluir la prestación de los servicios. En caso de que el Cliente tenga la intención de conservar cualquiera de las Cajas Boxmi, esto podrá hacerse, previo pago de una tarifa de $450.00 (Doscientos Ochenta Pesos 00/100 M.N.) por Caja Boxmi.</p>
      <p><br/></p>
      <p>“Clave de Acceso” significa la contraseña creada por el Cliente como parte del proceso de creación de una</p>
      <p>Cuenta de Usuario en la Plataforma.</p>
      <p><br/></p>
      <p>“Cuenta de Usuario” significa el registro que lleve a cabo el Cliente bajo la Plataforma, mediante el cual capturará sus Datos Personales y otra información confidencial y será utilizado para establecer comunicaciones de mensajes de datos con Boxmi.</p>
      <p><br/></p>
      <p>“Datos Personales” significa cualquier información concerniente a la identificación del Cliente, incluidos entre ellos, nombres, domicilio, teléfono, etc.</p>
      <p><br/></p>
      <p>“Domicilio de Entrega-Recepción” significa el domicilio que señale el Cliente por medio de la Plataforma para efectos de recoger y entregar los Artículos, mismo que deberá de ubicarse necesariamente en el área metropolitana de Monterrey, Nuevo León. Así mismo, será considerado como el Domicilio de Entrega-Recepción alternativo aquel registrado como tal en la Plataforma.</p>
      <p>“Guía del Límite de Responsabilidad de Boxmi” significa los lineamientos a seguirse en caso de que se actualicen los supuestos bajo la Cláusula Quinta de este Contrato, según dicha guía se describe en el Anexo 1 de este Contrato.</p>
      <p><br/></p>
      <p>“Guía para Almacenamiento de Artículos de Boxmi” significa los lineamientos con los que deben de cumplir los Artículos, según dicha guía se describe en el Anexo 2 de este Contrato.</p>
      <p><br/></p>



      <p>“Intereses Moratorios” significa los intereses generados por mora en el pago en términos de la Sección 3.3</p>
      <p>de este Contrato.</p>
      <p><br/></p>
      <p className="s7"><a href="http://www.boxmi.mx/" className="a" target="_blank">“Plataforma” significa el portal web “[</a>www.boxmi.mx<span className="p">]”, así como todas las páginas y direcciones “URL” derivadas o dependientes de éste y cualquier otra aplicación, software, portal, sitio web y demás medios tecnológicos por medio de los cuales Boxmi ofrezca sus servicios al público.</span></p>
      <p><br/></p>
      <p>“Sección” significa una sección en particular del Contrato.</p>
      <p><br/></p>
      <p>“Servicios” significa aquellos servicios descritos en Sección 2.1 de este Contrato.</p>
      <p><br/></p>
      <p>“Sitio” significa el espacio físico en posesión de Boxmi en el cual se almacenarán los Artículos. “Subsección” significa una subsección en particular de una Sección del Contrato.</p>
      <p>“Tarifa de Almacenamiento” significa la Tarifa descrita como tal en el Anexo 3 de este Contrato. “Tarifa de Transporte” significa la Tarifa descrita como tal en el Anexo 3 de este Contrato. “Tarifas” significa conjuntamente la Tarifa de Almacenamiento y la Tarifa de Transporte. “Términos y Condiciones” significa el apartado de cláusulas del Contrato.</p>
      <p className="s3">SEGUNDA. SERVICIOS.</p>
      <p><br/></p>




      <ol id="l1">
         <ol id="l2">
            <li data-list-text="2.1.">
               <h1 >DESCRIPCIÓN DE LOS SERVICIOS</h1>
               <ol id="l3">
                  <li data-list-text="(a)">
                     <p >Las Partes acuerdan que Boxmi prestará a favor del Cliente y sujeto al pago de las Tarifas aplicables, los servicios de almacenamiento en términos de este Contrato y con el objeto de almacenar en el Sitio los Artículos (los “Servicios”).</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >Boxmi se obliga a almacenar los Artículos en un espacio cerrado, a temperatura ambiente, con vigilancia, restringiendo el acceso al personal de Boxmi a los Artículos (salvo en aquellas ocasiones que sean necesario para la ejecución del presente Contrato) durante la Vigencia del Contrato.</p>
                     <p ><br/></p>
                     <p >Los Servicios no incluyen ningún tipo de mantenimiento, conservación, cuidado, refrigeración o trato especial de los Artículos, por lo que los mismos solamente serán almacenados y en términos de la presente Sección.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(c)">
                     <p >Sujeto a los horarios indicados en la Plataforma, la disponibilidad del personal de Boxmi o bien, a cualquier otro mensaje en contrario por parte de un representante de Boxmi, éste se obliga a transportar los Artículos del Domicilio de Entrega-Recepción al Sitio para su debido almacenamiento, una vez que así haya sido solicitado expresamente por el Cliente. Asimismo, sujeto a las condicionantes previamente citadas y ante la solicitud expresa del Cliente, la conclusión de la Vigencia o cualquier otra causa por la cual termine</p>
                  </li>
               </ol>
               <p >anticipadamente este Contrato, Boxmi se obliga a transportar los Artículos del Sitio hasta el Domicilio de Entrega-Recepción. Lo establecido en la presente Subsección “(c)” se encuentra sujeto al debido pago por parte del Cliente de las Tarifas aplicables y el cumplimiento de la Guía para Almacenamiento de Artículos de Boxmi (Anexo 2).</p>
               <p ><br/></p>
            </li>
            <li data-list-text="2.2.">
               <h1 >ARTÍCULOS</h1>
               <ol id="l4">
                  <li data-list-text="(a)">
                     <p >El Cliente garantiza y se obliga a que los Artículos cumplen debidamente con la Guía para Almacenamiento de Artículos de Boxmi, cuyo texto se incluye como Anexo 2 al presente Contrato. En ese sentido, siempre y cuando se le entregue a Boxmi los Artículos mediante Caja Sellada, Boxmi no tendrá acceso a la misma, salvo por cualquier caso fortuito o fuerza mayor o bien, que exista sospecha fundada y a entera discreción de Boxmi, de que los Artículos no cumplen con la Guía para Almacenamiento de Artículos de Boxmi, en cuyo caso Boxmi tendrá derecho a examinar los Artículos contenidos en la Caja Sellada, sin que sé considere que está incumpliendo con este Contrato y en su caso podrá proceder conforme a su derecho convenga en términos de este Contrato o en el supuesto de que estos cumplan con la Guía de Almacenamiento, volverá a sellar la caja correspondiente.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >Boxmi se compromete a devolver los Artículos en el Domicilio de Entrega-Recepción, en su caso, en las mismas condiciones en las que fueron recibidos, salvo por el deterioro ordinario causado por el paso del tiempo, el almacenamiento de los Artículos, daños prexistentes, así como los daños menores ocasionados por la transportación de los Artículos, tales como, enunciativa pero no limitativamente, rasguños, tallones, esquinas talladas, abolladuras, entre otros. Dichas condiciones de entrega se deberán documentar en el inventario de los Artículos al que se hace referencia en la Subsección (d) siguiente, que se realizará al momento de recepción de los referidos Artículos por parte de Boxmi, y del cual quedará constancia en la Plataforma. No obstante lo anterior, en caso de que los Artículos sean entregados en Caja Sellada, Boxmi no será responsable de cualquier daño o perjuicio a la integridad física de los Artículos contenidos dentro de la misma.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(c)">
                     <p >El Cliente no tendrá derecho a acceder al Sitio o extraer los Artículos del Sitio por su propia cuenta, a no ser que cuente con la autorización expresa de Boxmi.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(d)">
                     <p >Boxmi proporcionará un inventario fotográfico a través de la Plataforma que contendrá los Artículos recolectados. Todos los movimientos de devolución se realizarán en función de este inventario, y en ningún caso el Cliente podrá presentar reclamaciones sobre la ausencia de algún artículo adicional que no figure en dicho inventario.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(e)">
                     <p >El Cliente tendrá la opción de utilizar un servicio de transporte externo para entregar los Artículos en las instalaciones de Boxmi. En tal caso, el personal del servicio de transporte externo será responsable de transportar y descargar los Artículos en la entrada del Sitio, pero sobre la parte exterior del mismo. Una vez descargados dichos Artículos en la entrada del Sitio, el personal de Boxmi realizará el inventario referido en la Subsección (d) anterior exclusivamente de los Artículos recibidos, para posteriormente proceder a organizarlos adecuadamente. Al respecto, el Cliente reconoce que Boxmi no asumirá ninguna responsabilidad por los posibles daños en los Artículos que puedan surgir debido al servicio de transporte externo.</p>
                  </li>
               </ol>
               <p ><br/></p>
            </li>



            <li data-list-text="2.3.">
               <h1 >DEVOLUCIÓN DE LOS ARTÍCULOS</h1>
               <ol id="l5">
                  <li data-list-text="(a)">
                     <p >El Cliente tendrá en todo momento derecho a solicitar la devolución de todos o parte de los Artículos a través de los medios acordados en la Cláusula Octava de este Contrato y, sujeto al pago de las Tarifas aplicables y, a la disponibilidad del personal de Boxmi, ésta deberá entregarlos en el Domicilio de Entrega-Recepción, documentando en ese momento el inventario de devolución de los Artículos conforme al inventario al que se hace referencia en la Subsección (d) del apartado 2.2 y del cual quedará constancia en la Plataforma. En cualquier caso, los movimientos de devolución y recolección de los Artículos deberán ser</p>
                     <p >agendados con cuando menos 48 (cuarenta y ocho) horas de anticipación, y estarán sujetos a la disponibilidad del personal de Boxmi.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >En tanto no se haga el pago de la Tarifa aplicable y se liquide cualquier otra cantidad adeudada bajo la Cláusula Tercera de este Contrato a cargo del Cliente, Boxmi no tendrá la obligación de entregar los Artículos. En el caso descrito en el presente párrafo, se continuarán generando las Tarifas aplicables, así como los Intereses Moratorios y demás penas convencionales que apliquen.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(c)">
                     <p >El Cliente tendrá la opción de coordinar la devolución de los Artículos directamente en el Sitio mediante la asistencia de un servicio de transporte externo. En este caso, el compromiso de servicio por parte de Boxmi se considerará cumplido y completo al momento de entregar los Artículos directamente al personal del servicio de transporte externo en la entrada del Sitio y hecho el inventario de devolución al que se refiere la subsección (a) anterior. A partir de ese momento, cualquier daño a los Artículos o la falta de algún Artículo será responsabilidad exclusiva del Cliente y/o del servicio de transporte externo, renunciando el Cliente a cualquier derecho, reclamo, demanda, denuncia o procedimiento en contra de Boxmi relacionado con lo anterior. Se reitera que en ningún caso se permitirá el acceso del Cliente y/o del personal del servicio de transporte externo a las instalaciones de Boxmi, por lo tanto, la entrega deberá realizarse de manera exclusiva en la entrada del Sitio, pero sobre la parte exterior del mismo.</p>
                  </li>
               </ol>
               <p ><br/></p>
            </li>
            <li data-list-text="2.4.">
               <h1 >CONDICIONES DE USO DE LA PLATAFORMA</h1>
               <ol id="l6">
                  <li data-list-text="(a)">
                     <p >El Cliente manifiesta que en todo momento quien utilizará la Plataforma bajo su Cuenta de Usuario será él mismo o su representante con facultades suficientes para hacerlo. En ningún supuesto el Cliente permitirá el uso de la Plataforma a un menor de edad o cualquier otra persona con incapacidad legal y por ello deberá de tomar las medidas necesarias para impedir lo anterior.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >El Cliente se responsabiliza y manifiesta bajo protesta de decir verdad que todos y cada uno de los Datos Personales y demás información que ha ingresado a su Cuenta de Usuario son veraces por lo cual ni Boxmi, ni sus entidades controladoras, controladas, filiales o subsidiarias, así como tampoco sus partes relacionadas, accionistas, representantes, directivos, gerentes y/o demás empleados asumen cualquier tipo de responsabilidad, ni serán obligados, por cualquier daño ocasionado por el uso de dicha información.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(c)">
                     <p >El Cliente desde este momento expresa su conformidad en que Boxmi conserve en las bases de datos de la Plataforma los datos de tarjeta de crédito o débito que el Cliente ingrese y, autoriza a Boxmi para realizar cargos automáticos a dicha tarjeta de crédito o débito de las Tarifas aplicables y demás cargos o penalizaciones aplicables en términos de la Cláusula Tercera de este Contrato. En cualquier momento el Cliente podrá cancelar la realización de cargos automáticos, mediante manifestación por escrito a Boxmi.</p>
                     <p ><br/></p>
                     <p />
                     <p >En caso de que el Cliente no proporcione los datos de una tarjeta de crédito o débito, no se le podrá proporcionar el Servicio por medio de la Plataforma, por lo cual deberá de ponerse en contacto con Boxmi, para que pueda pagar por los Servicios conforme a los métodos de pago que le informe para tal efecto y hecho lo anterior, poder contratar de Boxmi los Servicios.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(d)">
                     <p >La Plataforma es propiedad de Boxmi por lo que el Cliente no deberá y no permitirá o facilitará a cualquier otra persona a copiar, descompilar, realizar ingeniería inversa, desmontar, alterar o reducir cualquier componente de la Plataforma y, no deberá proporcionar a terceros en el sentido más amplio cualquier elemento, componente o información relacionada con la Plataforma, incluyendo su Clave de Acceso. Asimismo, el Cliente se obliga notificar a Boxmi de cualquier situación que le cause o que le pueda causar perjuicio a Boxmi, siendo el Cliente responsable del pago de los daños y perjuicios ocasionados a Boxmi por incumplimiento a todo lo anterior.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(e)">
                     <p >El Cliente creará su Clave de Acceso para que éste acceda a la Plataforma. Será responsabilidad del Cliente conservar y salvaguardar la Clave de Acceso, por lo que se obliga a responder a Boxmi de cualquier</p>
                  </li>
               </ol>
               <p >daño y/o perjuicio que el mal uso, error o negligencia en el tratamiento de la Clave de Acceso por parte del Cliente le cause a Boxmi.</p>
               <p ><br/></p>
            </li>


            <li data-list-text="2.5.">
               <h1>NATURALEZA JURÍDICA DE LOS SERVICIOS</h1>
            </li>
         </ol>
      </ol>
      <ol id="l7">
         <li data-list-text="(a)">
            <p >Las Partes acuerdan que el presente es un Contrato de Prestación de Servicios de Almacenamiento y por ningún motivo se podrá considerar que Boxmi se encuentra arrendando o subarrendado el Sitio y/o cualquier otro espacio. En esa inteligencia, el Cliente no adquiere por este medio derecho alguno sobre todo o parte del Sitio.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >En este acto, se reconoce que Boxmi no adquiere carácter de depositario de acuerdo a los artículos 2410 al 2439 del Código Civil para el Estado de Nuevo León. Por lo mismo, Boxmi no tendrá obligación alguna de depositario, incluyendo sin limitar: ejecutar el cobro de intereses por Artículos que los generen, realizar cualquier acto para que los Artículos conserven su valor, dar avisos al Cliente sobre cualquier afectación de los Artículos, entre otros. En ese sentido, las Partes reconocen que Boxmi únicamente adquiere las obligaciones contraídas explícitamente en este Contrato, por lo cual el Cliente renuncia expresamente a ejercer en relación con lo anterior, cualquier tipo de acción, queja o demanda en contra de Boxmi.</p>
         </li>
      </ol>






      <p ><br/></p>
      <p className="s3" >TERCERA. TARIFAS Y OTROS PAGOS.</p>
      <p ><br/></p>
      <ol id="l8">
         <ol id="l9">
            <li data-list-text="3.1.">
               <h1 >TARIFA DE ALMACENAMIENTO</h1>
               <ol id="l10">
                  <li data-list-text="(a)">
                     <p >El Cliente se obliga a pagar a Boxmi, de forma mensual y por adelantado, dentro de los primeros 5 (cinco) días naturales de cada mes, la Tarifa de Almacenamiento. Esta tarifa se genera por cada mes o fracción que el Cliente utilice los Servicios materia de este Contrato.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >El Cliente autoriza a Boxmi para que realice los cargos automáticos del pago de la Tarifa de Almacenamiento a la tarjeta de crédito o débito del Cliente que se encuentre vigente y haya ingresado en la Cuenta de Usuario. Dicha autorización se hace extensiva a cualquier otra Tarifa, pena convencional, o monto adeudado a Boxmi bajo el presente Contrato.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(c)">
                     <p >El pago de la Tarifa de Almacenamiento incluye el almacenamiento mensual.</p>
                  </li>
               </ol>
               <p ><br/></p>
            </li>
            <li data-list-text="3.2.">
               <h1>TARIFA DE TRANSPORTE</h1>
               <ol id="l11">
                  <li data-list-text="(a)">
                     <p >El Cliente se obliga a pagar a Boxmi la Tarifa de Transporte cada vez que requiera que se transporte cualquiera de los Artículos, ya sea desde el Domicilio de Entrega-Recepción hacia el Sitio o del mismo Sitio hacia el Domicilio de Entrega-Recepción.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >El Cliente autoriza a Boxmi para que realice los cargos automáticos del pago de la Tarifa de Transporte a la tarjeta de crédito del Cliente que se encuentre vigente y haya ingresado en la Cuenta de Usuario.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(c)">
                     <p >La Tarifa de Transporte se determinará a entera discreción de Boxmi, en función de la distancia de transporte, el volumen de artículos a transportar y la complejidad tanto de la recolección como de la devolución de los Artículos.</p>
                  </li>
               </ol>
               <p ><br/></p>
               <p >La Tarifa de Transporte solamente incluye la carga o descarga de los Artículos desde o a la entrada del Domicilio de Entrega-Recepción. El acomodo, armado o desarmado, recolección o entrega en segundo pisos, patios o lugares diferentes tendrá un costo adicional que en su momento deberá de ser determinado por Boxmi</p>
               <p >La Tarifa de Transporte tampoco incluye el empaque, sellado, vaciado o cualquier otro proceso previo al transporte de los Artículos, ya que esto deberá de ser realizado por el Cliente.</p>
               <p ><br/></p>
               <p >Boxmi podrá negarse a realizar recolecciones de Artículos que excedan los parámetros establecidos en el anexo 2.</p>
               <p ><br/></p>
            </li>
            <li data-list-text="3.3.">
               <h1 >INTERESES MORATORIOS Y RETENCIÓN DE ARTÍCULOS</h1>
            </li>
         </ol>
      </ol>
      <ol id="l12">
         <li data-list-text="(a)">
            <p >En este acto, las Partes pactan un interés moratorio diario, el cual será equivalente a la cantidad de</p>
            <p />
            <p />
            <p >$10.00 (diez pesos 00/100 M.N.) por cada metro cuadrado o fracción que ocupen los Artículos en el Sitio. En su caso, esta tasa se aplicará sobre saldos insolutos y será exigible a partir de que el Cliente incumpla con cualquier obligación de pago que le corresponda; lo anterior, tomando en cuenta que la mora se contará y computará desde el primer día posterior al incumplimiento sin necesidad de requerimiento o interpelación alguna por parte de Boxmi. El interés que corresponda se calculará por cada día o fracción de día mientras dure la mora y durante todo el tiempo transcurrido desde la fecha en que venció la obligación de pago<span>, </span>hasta la fecha en la que se realice efectivamente el pago.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >El Cliente reconoce expresamente el derecho de Boxmi de retener los Artículos en caso de que exista cualquier adeudo por cualquier concepto derivado del presente Contrato a cargo del Cliente. En este caso, los pagos de las Tarifas se seguirán generando de la misma manera, hasta que el Cliente haga el pago total de lo adeudado, incluyendo sus intereses correspondientes.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >CUARTA. RESPONSABILIDAD DEL CLIENTE.</p>
      <ol id="l13">
         <li data-list-text="(a)">
            <p >El Cliente adquiere total responsabilidad por los Artículos y por cualquier incumplimiento a la Guía para Almacenamiento de Artículos de Boxmi. En ese entendido, el Cliente se obliga a sacar en paz y a salvo e indemnizar (en caso de que sea aplicable) a Boxmi de cualquier controversia y/o situación que se suscite en su contra derivado de la ilicitud o procedencia ilícita de los Artículos, o por incumplimiento de los lineamientos establecidos en la Guía para Almacenamiento de Artículos de Boxmi. Así mismo, el Cliente se obliga a indemnizar y a sacar en paz y a salvo a Boxmi de cualquier reclamación hecha por un tercero a Boxmi que se relacione, directa o indirectamente con los Artículos.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >Al momento en que Boxmi se percate de Artículos que no cumplan con la Guía para Almacenamiento de Artículos de Boxmi, este último lo hará del conocimiento del Cliente para que, dentro de las 48 (cuarenta y ocho) horas siguientes a su notificación, el propio Cliente proceda a su cargo y costa a recolectar los Artículos en cuestión en la entrada del Sitio, pero sobre la parte exterior del mismo o en su caso, a solicitar la entrega de los mismos en el mismo Domicilio de Entrega-Recepción, con el pago de la correspondiente Tarifa de Transporte. Una vez transcurrido dicho plazo sin que el Cliente haya acudido a recolectar los referidos Artículos o solicitado la entrega de estos pagando la Tarifa de Transporte, este último faculta a Boxmi para desechar o deshacerse de los Artículos almacenados que no cumplan con la Guía para Almacenamiento de Artículos de Boxmi, sin responsabilidad alguna.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >QUINTA. RESPONSABILIDAD DE BOXMI.</p>
      <ol id="l14">
         <li data-list-text="(a)">
            <p >El Cliente, en este acto, asume de forma personal todos los riesgos y peligros de pérdida, daño, robo o cualquier otra que afecte a los Artículos, liberando a Boxmi de cualquier responsabilidad que pueda suscitarse, incluyendo sin limitar: desaparición, incendio, inundación, daño por cualquier animal, vandalismo, enmohecimiento, moldeo, entre otros.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >Boxmi no será responsable por daños ocasionados a los Artículos en ninguna circunstancia, a excepción de que medie dolo o negligencia debidamente acreditada por parte de Boxmi. En caso de que se actualice</p>
            <p >la situación descrita al inicio de este inciso, se aplicará lo establecido en la Guía del Límite de Responsabilidad de Boxmi.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(c)">
            <p >Boxmi no ofrece ningún tipo de garantía para la integridad y/o bienestar de los Artículos, incluyendo sin limitar de su seguridad, salvo los parámetros establecidos en el anexo 1 del presente contrato.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(d)">
            <p >Boxmi tampoco será responsable por los daños directos, indirectos, especiales, punitivos, fortuitos o consecuentes (incluyendo, sin limitación, aquellas que resulten de beneficios perdidos, datos perdidos o interrupción del negocio) resultantes del uso, de la inhabilidad de utilizar, o de los resultados del uso de los Servicios.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(e)">
            <p >La responsabilidad de Boxmi estará limitada en todo momento y sin excepción al importe total correspondiente conforme a la Guía del límite de responsabilidad de Boxmi.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(f)">
            <p >El Cliente podrá asegurar los Artículos a su propio cargo y costa, mediante la contratación de una compañía aseguradora externa a este Contrato. Lo anterior, sin que Boxmi esté obligado a asumir ninguna obligación al respecto.</p>
         </li>
      </ol>





      <p ><br/></p>
      <p className="s3" >SEXTA. VIGENCIA.</p>
      <p ><br/></p>
      <ol id="l15">
         <ol id="l16">
            <li data-list-text="6.1.">
               <h1 >VIGENCIA INICIAL.</h1>
               <p >El presente Contrato iniciará su vigencia a partir de la aceptación por parte del Cliente de los presentes Términos y Condiciones, y tendrá una vigencia indefinida por todo el tiempo en que se presten los Servicios (la “Vigencia”).</p>
               <p ><br/></p>
               <p >Así mismo, por medio de la Plataforma, el Cliente tendrá la opción de elegir libremente entre los Planes de Plazos de duración de los Servicios y que se describen en el Anexo 3 de este Contrato, reconociendo además el Cliente que una vez seleccionado, dicha selección del Plan de Plazos, no podrá modificarse en el futuro, además de que esos Planes serán de cumplimiento forzoso. Así mismo, el Cliente comprende que la solicitud de devolución de los Artículos, no lo eximirá del cumplimiento de la obligación de liquidar los meses restantes conforme al Plan de Plazos seleccionado.</p>
               <p ><br/></p>
            </li>
            <li data-list-text="6.2.">
               <h1 >CONDICIONES ADICIONALES</h1>
            </li>
         </ol>
      </ol>
      <ol id="l17">
         <li data-list-text="(a)">
            <p >La fecha de Terminación de Vigencia se prorrogará hasta el día en que efectivamente el Cliente haya recibido en su domicilio los Artículos en su totalidad.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >Para la devolución de los Artículos, el Cliente deberá pagar por concepto de traslado lo establecido en la Tarifa de Transporte respectiva, a no ser que el Cliente decida recoger los Productos en el Sitio, según lo establecido en este Contrato.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >SÉPTIMA. DERECHOS ADICIONALES DE BOXMI.</p>
      <p >En caso de que el Cliente incurra en mora en el pago de la Tarifa de Almacenamiento, Tarifa de Transporte, o bien respecto de cualquier otra obligación de carácter económica a cargo del Cliente, Boxmi podrá, a su propia elección:</p>
      <p ><br/></p>
      <ol id="l18">
         <li data-list-text="(a)">
            <p >Continuar con la prestación de los Servicios, por lo cual se continuarán generando los cargos del pago de la Tarifa de Almacenamiento y de cualquier otra contraprestación económica derivada del presente Contrato a cargo del Cliente; o</p>
         </li>
         <li data-list-text="(b)">
            <p >En caso de retraso en 3 (tres) o más pagos de la Tarifa de Almacenamiento en forma consecutiva, o bien en caso de terminación del presente Contrato por causas imputables al Cliente (enlistadas en la Cláusula 9.2 de este Contrato) en este acto, el Cliente autoriza de la forma más amplia de acuerdo a lo que en derecho corresponda a Boxmi para que disponga, sin responsabilidad alguna para Boxmi, de los Artículos a la manera que más le convenga de acuerdo al criterio de Boxmi. Lo anterior incluye, sin limitar a, vender, arrendar, o disponer de los Artículos de cualquier forma, incluso darle trato como desecho o basura, esto con el fin de que con el dinero que se obtenga, compensar las cantidades adeudadas por el Cliente a Boxmi. El Cliente se obliga a pagar a Boxmi cualquier erogación o costo en el que incurra para ejecutar los actos descritos en el presente inciso. Asimismo, en este acto, el Cliente libera de todo tipo de responsabilidad a Boxmi por ejercer el derecho comprendido en este inciso y renuncia a cualquier derecho, reclamo, demanda, denuncia o procedimiento en contra de Boxmi relacionado con la disposición de los Artículos por éste en términos de esta Subsección; o</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(c)">
            <p >Hacer entrega de los Artículos en el domicilio que el Cliente señale en la Plataforma como Domicilio de Entrega-Recepción o, en su caso, en el Domicilio de Entrega-Recepción alternativo. El Cliente se obliga a pagar a Boxmi cualquier erogación de cualquier tipo que deba realizar por la ejecución de los actos descritos en el presente inciso, incluso si llegan a ser mayores que la Tarifa de Transporte. Así mismo, en este acto, el Cliente libera de todo tipo de responsabilidad a Boxmi por ejercer el derecho comprendido en este inciso.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >OCTAVA. COMUNICACIONES.</p>
      <ol id="l19">
         <li data-list-text="(a)">
            <p >Las Partes acuerdan que utilizarán la Plataforma y los protocolos y mecanismos en ella, como una forma de comunicación válida para cualquier solicitud o petición relacionada con la operación de los Servicios.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p ><a href="mailto:hola@boxmi.mx" className="a" target="_blank">Las Partes acuerdan que para cualquier cuestión relacionada con el presente Contrato, se deberá de notificar de manera fehaciente al domicilio o correo electrónico de las Partes. Para este efecto, Boxmi señala como su domicilio convencional para oír y recibir notificaciones y su correo electrónico, los siguientes: Avenida Lazaro Cardenas 2424, Tercer Piso, Residencial San Agustin Primer Sector, San Pedro Garza García, Nuevo León, México C.P. 66260. Correo Electrónico: </a><a href="mailto:hola@boxmi.mx" target="_blank">hola@boxmi.mx</a></p>
            <p ><br/></p>
            <p >El Cliente reconoce para los efectos legales conducentes que su domicilio para oír y recibir notificaciones es aquél que proporcionó al momento de crear su Cuenta de Usuario en la Plataforma.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(c)">
            <p >Las notificaciones y comunicaciones referidas en este Contrato se podrán hacer mediante la entrega del aviso o notificación correspondiente en los domicilios señalados previamente o bien, mediante mensaje enviado al correo electrónico señalado en líneas anteriores. Las notificaciones se entenderán legalmente hechas cuando se practiquen de forma fehaciente en el domicilio señalado, o bien, en el caso del correo electrónico (que en el caso del Cliente, será aquel registrado en la Plataforma directamente por parte de dicho Cliente al momento de crear su Cuenta de Usuario), cuando la notificación se haya enviado a la dirección de correo electrónico previamente señalada (en el caso de las comunicaciones realizadas a Boxmi, será necesario que haya un acuse de recibo de dicha notificación). En caso de que el Cliente desee modificar su domicilio o correo electrónico, deberá realizar el ajuste correspondiente a través de la Plataforma con cuando menos 10 (diez) días de anticipación y, en caso de no hacerlo, se entenderán como legalmente practicadas las notificaciones hechas en el domicilio o a la dirección de correo electrónico aquí señaladas.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >NOVENA. TERMINACIÓN.</p>
      <p ><br/></p>
      <ol id="l20">
         <ol id="l21">
            <li data-list-text="9.1.">
               <h1 >TERMINACIÓN SIN RESPONSABILIDAD PARA NINGUNA DE LAS PARTES</h1>
               <p >El presente Contrato se dará por terminado sin responsabilidad para ninguna de las Partes en los siguientes casos:</p>
               <ul id="l22">
                  <li data-list-text="">
                     <p >i. Por la clausura del Sitio;</p>
                  </li>
                  <li data-list-text="">
                     <p >ii. Por así notificarlo Boxmi al Cliente con al menos 30 (treinta) días naturales de anticipación; o</p>
                  </li>
                  <li data-list-text="">
                     <p >iii. Por así notificarlo el Cliente a Boxmi con al menos 30 (treinta) días naturales de anticipación.</p>
                     <p ><br/></p>
                     <p >En el caso de que el Cliente sea quien termine el presente Contrato, este deberá de liquidar a más tardar la fecha efectiva de terminación cualquier obligación de pago que tenga pendiente, incluido el pago de las tarifas pendientes que tuviere con relación al Plan de Plazos.</p>
                     <p ><br/></p>
                  </li>
               </ul>
            </li>
            <li data-list-text="9.2.">
               <h1>RESCISIÓN CON RESPONSABILIDAD PARA EL CLIENTE</h1>
            </li>
         </ol>
      </ol>




      <ol id="l23">
         <li data-list-text="(a)">
            <p >Boxmi tendrá facultad de rescindir el presente Contrato, sin responsabilidad de ningún tipo, en los siguientes casos:</p>
            <p ><br/></p>
            <ul id="l24">
               <li data-list-text="">
                  <p >i. Si el Cliente es omiso en cualquier pago de las Tarifas o el pago de cualquier otro tipo de contraprestación económica a su favor bajo el presente Contrato;</p>
               </li>
               <li data-list-text="">
                  <p >ii. En caso de que la tarjeta de crédito o débito registrada por el Cliente llegue a su vencimiento, se quede sin fondos y/o por cualquier motivo no permita hacer efectivo el pago correspondiente, y éste no registre una nueva tarjeta dentro de los 15 (quince) días naturales de que se le dio aviso a través de la Plataforma u otros métodos de comunicación establecidos en este Contrato;</p>
               </li>
               <li data-list-text="">
                  <p >iii. Si cualquier autoridad de cualquier ámbito, incluyendo sin limitar de la esfera federal, estatal o municipal, solicita a Boxmi la entrega de los Artículos. En esta situación, el Cliente autoriza ampliamente a Boxmi para que realice la entrega respectiva sin ninguna responsabilidad para Boxmi;</p>
               </li>
               <li data-list-text="">
                  <p >iv. En caso de que cualquier información proporcionada por el Cliente por medio de la Plataforma resultase falsa;</p>
               </li>
               <li data-list-text="">
                  <p >v. Si el Cliente incumple con cualquier obligación a su cargo derivado de este Contrato o cualquier incumplimiento a cualquier ley, reglamento, normativa o disposición que le sea aplicable.</p>
                  <p ><br/></p>
               </li>
            </ul>
         </li>
         <li data-list-text="(b)">
            <p >En cualquier de los casos anteriores, el Cliente deberá pagar a Boxmi la cantidad de la Tarifa de Almacenamiento total restante durante la Vigencia y conforme al Plan de Plazos seleccionado.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(c)">
            <p >En caso que el Cliente no haga el pago de las Tarifas aplicables, o el pago de cualquier otro tipo de contraprestación económica, en la fecha correspondiente, y Boxmi incurriera en gastos de cobranza judiciales o extrajudiciales, incluyendo de manera enunciativa más no limitativa, gastos por honorarios legales, mediación, transporte, comunicación o cualquier otro tipo de gasto o costo en los que incurra Boxmi con motivo del retraso del Cliente (los “Gastos de Cobranza”), el Cliente se obliga a pagar a Boxmi (a la vista) aquellos Gastos de Cobranza más el Impuesto al Valor Agregado correspondiente.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >DÉCIMA. IMPUESTOS.</p>
      <p >Las Partes convienen en que el pago de todos y cada uno de los impuestos que con motivo del presente Contrato se causen o pudieran causarse, serán responsabilidad exclusiva de cada una de ellas, en la forma y término que al efecto señalen las leyes fiscales que resulten aplicables tanto en México como en el extranjero.</p>
      <p ><br/></p>
      <p className="s3" >DÉCIMA PRIMERA. CONFIDENCIALIDAD.</p>
      <p >El Cliente acuerda mantener en confidencialidad toda la información presentada en este documento y la aportada durante las operaciones de la prestación del servicio de almacenamiento referido en este Contrato. Para los propósitos del presente Contrato, se entenderá por &quot;Información Confidencial&quot; cualquier información de Boxmi que se encuentre, ya sea actualmente o en cualquier momento, en posesión del Cliente, la cual sea relacionada con los negocios de Boxmi, incluyendo sin limitación alguna, documentos,</p>
      <p >datos, información técnica o financiera, planos, diseños, fotografías, dibujos, especificaciones y cualquier otro material que ostente o lleve consigo cualquier información relacionada con este Contrato. También se considerará como &quot;Información Confidencial&quot; todos los programas, sistemas, interfaces, aplicaciones, herramientas, código fuente, código objeto, productos de cómputo, servidor de web, servidores de bases de datos relacionados con la Plataforma.</p>
      <p ><br/></p>
      <p >En caso de que el Cliente incumpla con alguna de sus obligaciones de confidencialidad bajo este Contrato, el Cliente debe indemnizar a Boxmi por todos y cada uno de los daños ocasionados. De acuerdo a lo anterior, el Cliente quedará, además, sujeto a las multas o sanciones establecidas en el Código Penal de los Estados Unidos Mexicanos que resulte aplicable.</p>
      <p ><br/></p>
      <p className="s3" >DÉCIMA SEGUNDA. DATOS PERSONALES.</p>
      <p ><br/></p>
      <ol id="l25">
         <ol id="l26">
            <li data-list-text="12.1.">
               <h1 >ACEPTACIÓN DE RECOPILACIÓN DE DATOS PERSONALES</h1>
               <ol id="l27">
                  <li data-list-text="(a)">
                     <p >En este acto, el Cliente está de acuerdo en que Boxmi adquiera y resguarde sus Datos Personales, mismos que el Cliente ha ingresado a la Plataforma.</p>
                     <p ><br/></p>
                  </li>
                  <li data-list-text="(b)">
                     <p >Para efectos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás normatividad aplicable, Boxmi se obliga expresamente a que los (i) Datos Personales, y (ii) datos patrimoniales o financieros que se encuentren en poder de Boxmi por virtud de los Servicios serán protegidos y tratados de conformidad con lo que se establece en el Contrato, para dar cumplimiento a las obligaciones que le corresponden en términos de éste Contrato,.</p>
                  </li>
               </ol>
               <p ><br/></p>
            </li>
            <li data-list-text="12.2.">
               <h1 >FINES DEL USO DE LOS DATOS PERSONALES</h1>
            </li>
         </ol>
      </ol>
      <ol id="l28">
         <li data-list-text="(a)">
            <p >Los Datos Personales del Cliente serán utilizados por Boxmi únicamente con los siguientes fines: (i) para elaborar datos estadísticos para distintos fines con la única y exclusiva condición de que no se revele la identificación directa del propietario de la información utilizada para los estadísticos (nombre especifico de la empresa, datos personales específicos), (ii) devolver al Cliente toda la información que contenga Datos Personales y que se encuentre contenida en medios físicos, electrónicos y magnéticos o mediante cualquier otro medio existente, (iii) brindar los Servicios acordados en el presente Contrato y cumplir con las obligaciones derivados del mismo y (iv) con fines mercadotécnicos, publicitarios o de prospección comercial.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >Derivado de lo anterior, el Cliente acuerda que su información referida en esta Cláusula sea tratada de acuerdo a lo contenido en el Aviso de Privacidad y la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, en adición a lo ya acordado en esta Cláusula. Así mismo, el Cliente se obliga a otorgar los mismos derechos y obligaciones en materia de cuidado de datos personales a Boxmi respecto a los datos que obtenga de Boxmi, siempre predominando las obligaciones en materia de confidencialidad.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s3" >DÉCIMA TERCERA. NO RELACIÓN LABORAL.</p>
      <p >Queda expresamente establecido que Boxmi no se encuentra subordinada ni jerárquica, ni económicamente al Cliente, ni viceversa, por lo que ambas Partes convienen que ninguna obtiene respecto de la otra por la firma de este Contrato, responsabilidad laboral alguna para con la otra.</p>
      <p ><br/></p>
      <p className="s3" >DÉCIMA CUARTA. CESIÓN.</p>
      <ol id="l29">
         <ol id="l30">
            <li data-list-text="14.1.">
               <p >Cesión por parte del Cliente. El Cliente no podrá ceder sus derechos y/o obligaciones derivados del presente Contrato a menos de que cuente con la autorización expresa y por escrito de Boxmi.</p>
            </li>
            <li data-list-text="14.2.">
               <p >Cesión por parte de Boxmi. Boxmi queda facultado para ceder sus derechos y/o obligaciones derivados del presente Contrato sin necesidad de autorización alguna por parte del Cliente, otorgando únicamente aviso por escrito.</p>
            </li>
         </ol>
      </ol>





      <p ><br/></p>
      <p className="s3" >DÉCIMA QUINTA. VARIOS.</p>
      <ol id="l31">
         <ol id="l32">
            <li data-list-text="15.1.">
               <p >Acuerdo total. Este Contrato representa el acuerdo total e integral entre las Partes y deja sin efectos todas las negociaciones, declaraciones o convenios anteriores, escritos o verbales, entre las Partes con respecto a dicha materia.</p>
               <p ><br/></p>
            </li>
            <li data-list-text="15.2.">
               <p >Modificaciones al Contrato. El Cliente reconoce estar de acuerdo en que Boxmi se reserva el derecho a modificar el presente Contrato y a establecer nuevos Términos y Condiciones en cualquier momento. Tales modificaciones y/o Términos y Condiciones adicionales le serán comunicados al Cliente oportunamente mediante comunicación en las formas establecidas en la Cláusula Octava del presente Contrato. La utilización de los Servicios por parte del Cliente significará la aceptación de este a los nuevos Términos y Condiciones. Si el Cliente no está de acuerdo con las modificaciones a los Términos y Condiciones, debe abstenerse de continuar utilizando los Servicios.</p>
               <p ><br/></p>
            </li>
            <li data-list-text="15.3.">
               <p >Encabezados. Los encabezados en este Contrato se insertan únicamente como referencia y no podrán ser utilizados en la interpretación del mismo.</p>
               <p ><br/></p>
            </li>
            <li data-list-text="15.4.">
               <p >Divisibilidad de las Cláusulas. En caso de cualquiera de las disposiciones contenidas en el presente Contrato fuere declarada nula o inválida por un tribunal competente, dicha disposición se tomará por no puesta, continuando en vigor el resto del contenido del presente Contrato.</p>
               <p ><br/></p>
            </li>
            <li data-list-text="15.5.">
               <p >Términos en singular y/o plural. Los términos empleados en singular y/o plural en este Contrato, deberán entenderse aplicables también en el otro sentido (sea singular o plural), a menos disposición expresa opuesta en este Contrato</p>
            </li>
         </ol>
      </ol>
      <p ><br/></p>
      <p className="s3" >DÉCIMA SEXTA. VICIOS DEL CONSENTIMIENTO Y JURISDICCIÓN Y LEGISLACIÓN APLICABLE.</p>
      <ol id="l33">
         <li data-list-text="(a)">
            <p >Acuerdan las Partes firmar el presente Contrato en los términos en que se estipula, ya que no obraron vicios en su consentimiento, mala fe, dolo o violencia.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="(b)">
            <p >Acuerdan las Partes que en caso de sobrevenir cualquier controversia derivada de la interpretación o cumplimiento de este Contrato serán aplicables las disposiciones del Código Civil vigente en el estado de Nuevo León, y serán competentes exclusivamente los tribunales de la ciudad de Monterrey, Nuevo León para resolver cualquier conflicto, sin importar el domicilio presente o futuro que las partes llegaran a tener.</p>
         </li>
      </ol>
      <p ><br/></p>
      <p >Estos Términos y Condiciones fueron actualizados el 10 de octubre de 2023.</p>
      <p className="s1" >Anexo I</p>
      <p className="s3" >Guía del límite de responsabilidad de Boxmi</p>
      <p ><br/></p>
      <h1 >COMPENSACIÓN MÁXIMA POR METRO CUADRADO</h1>
      <p >En el caso aplicable de acuerdo a lo contenido en el Contrato, Boxmi pagará al Cliente, como cantidad máxima por concepto de compensación, lo estipulado en la siguiente tabla:</p>
      <p ><br/></p>
      <p >Almacenaje de 1 a 2 metros cuadrados: $2,000.00 MXN Almacenaje de 3 a 10 metros cuadrados: $5,000.00 MXN Almacenaje de 11 a 20 metros cuadrados: $12,500.00 MXN Almacenaje de más de 21 metros cuadrados: $20,000.00 MXN</p>
      <h1 >COMPENSACIÓN MÁXIMA TOTAL/ACUMULATIVA</h1>
      <p >Independientemente de lo referido en el numeral I del presente Anexo, en todo caso, la compensación máxima total acumulativa no rebasará de $25,000 MXN (veinte cinco mil pesos 00/100 moneda nacional) durante la Vigencia total del presente Contrato.</p>
      <p ><br/></p>
      <p className="s1" >Anexo 2</p>
      <p className="s3" >Guía para Almacenamiento de Artículos de Boxmi</p>
      <p ><br/></p>
      <ol id="l34">
         <li data-list-text="I.">
            <h1 >LINEAMIENTOS PARA EMPAQUE DE LOS ARTÍCULOS</h1>
         </li>
         <li data-list-text="II.">
            <h1 >ARTÍCULOS PROHIBIDOS</h1>
            <p >Se prohíbe solicitar el almacenamiento a Boxmi de cualquier artículo que cuente con alguna de las siguientes características:</p>
            <p ><br/></p>
            <ul id="l35">
               <li data-list-text="">
                  <p >Cualquier artículo, material o bien ilícito, o bien, cualquier artículo cuya posesión de cualquier forma viole alguna ley, reglamento o normatividad en los Estados Unidos Mexicanos;</p>
               </li>
               <li data-list-text="">
                  <p >Dinero en efectivo, centenarios, lingotes de oro;</p>
               </li>
               <li data-list-text="">
                  <p >Artículos perecederos, biodegradables, organismos vivientes, artículos orgánicos, aquellos que requieran de algún tipo de refrigeración o almacenamiento especial no acordado en los Términos y Condiciones</p>
               </li>
               <li data-list-text="">
                  <p >Armas de fuego, pólvora, explosivos, dinamita, artículos pirotécnicos, armas punzocortantes, etc.</p>
               </li>
               <li data-list-text="">
                  <p >Cualquier artículo o sustancia inflamable, peligrosa o inestable</p>
               </li>
               <li data-list-text="">
                  <p >Cualquier sustancia o material peligroso, contaminante, sustancia peligrosa, material peligroso, sustancia tóxica, sustancias radioactivas;</p>
               </li>
               <li data-list-text="">
                  <p >Elementos, substancias, compuestos, o mezcla de ellos que, independientemente de su estado físico, represente un riesgo para el ambiente, la salud o los recursos naturales, por sus características corrosivas, reactivas, explosivas, tóxicas, inflamables o biológico infecciosas</p>
               </li>
               <li data-list-text="">
                  <p >Artículos contaminados con comida, polvo, aceite, tierra u otros tipos de suciedad.</p>
               </li>
               <li data-list-text="">
                  <p >Plantas, flores o tierra.</p>
                  <p ><br/></p>
                  <p >Se podrá solicitar a Boxmi el almacenamiento de Artículos que por sí solos tengan un valor mayor a</p>
                  <p >$25,000.00 MXN. En este caso, en ninguna circunstancia, incluyendo aquellas pactadas en el presente Contrato, darán lugar a responsabilidad alguna de Boxmi.</p>
                  <p ><br/></p>
               </li>
            </ul>
         </li>
         <li data-list-text="III.">
            <h1 >DIMENSIONES MÁXIMAS Y MÍNIMAS</h1>
            <p >Mínimas: 5cm X 5cm X 5cm Máximas: 200 cm X 200 cm X 200cm</p>
         </li>
         <li data-list-text="IV.">
            <h1 >PESO MÁXIMO</h1>
            <p >Peso Máximo: 100kgs</p>
            <p ><br/></p>
         </li>
         <li data-list-text="V.">
            <h1 >ARTÍCULOS NO RECOMENDADOS</h1>
            <p >Los siguientes Artículos si podrán ser almacenados, más no es recomendado. Se almacenarían exclusivamente bajo riesgo del Cliente por su naturaleza frágil. Boxmi queda exento de cualquier reclamación con relación a estos Artículos.</p>
            <p ><br/></p>
            <ul id="l36">
               <li data-list-text="">
                  <p >Artículos de mármol, porcelana, vidrio, cristal, barro o espejos.</p>
               </li>
               <li data-list-text="">
                  <p >Artículos de alto valor.</p>
               </li>
               <li data-list-text="">
                  <p >Equipos electrónicos como; televisiones, tabletas, computadoras, celulares.</p>
               </li>
               <li data-list-text="">
                  <p >Artículos de cocina; platos, copas, tasas, entre otros.</p>
               </li>
               <li data-list-text="">
                  <p >Cuadros.</p>
               </li>
               <li data-list-text="">
                  <p >Artículos o documentos que tengan un valor intrínseco, tales como pagarés, cheques, tarjetas de crédito, débito, facturas contratos o cualquier otro similar que por si mismo tenga un valor y sea irremplazable.</p>
               </li>
            </ul>
         </li>
      </ol>
      <p ><br/></p>
      <p className="s1" >Anexo 3</p>
      <p className="s3" >Tarifas Aplicables a los Servicios</p>
      <p ><br/></p>






      <ol id="l37">
         <li data-list-text="I.">
            <h1 >ALMACENAMIENTO</h1>
            <p >La Tarifa de Almacenamiento se determinará mediante la suma de todas y cada una de las Cuotas por Metro Cuadrado. Es importante destacar que Boxmi se reserva el derecho de modificar al alza o a la baja el plan del Cliente con el fin de garantizar que este abone únicamente por el espacio efectivamente utilizado y lo cual estará plenamente justificado con soportes suficientes y considerando la experiencia de Boxmi.</p>
            <p ><br/></p>
            <p >A la Tarifa de Almacenamiento se le adicionará el Impuesto al Valor Agregado (I.V.A.) aplicable.</p>
            <p >La Cuota por Metro Cuadrado se determinará mediante la siguiente fórmula: <span className="s6">(Cantidad de metros cuadrados) x (Precio) = Tarifa de Almacenamiento.</span></p>
            <p ><br/></p>
            <p >La Cuota por Metro Cuadrado será incrementada anualmente (de forma automática y sin notificación previa) de acuerdo al Índice Nacional de Precios al Consumidor (INPC) publicado por el Instituto Nacional de Estadística y Geografía (INEGI), en el Diario Oficial de la Federación, tomando como base la inflación de los doce últimos meses anteriores a la fecha de revisión. Las revisiones a la Cuota por Artículo serán en el mes de enero de cada año de la duración del presente Contrato y cualquiera de sus prórrogas.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="II.">
            <h1 >TRANSPORTACIÓN</h1>
            <p >Por cada ocasión que Boxmi haga entrega o recolección de cualquiera de los Artículos, el Cliente pagará la tarifa, por cada devolución más el I.V.A. correspondiente.</p>
            <p ><br/></p>
            <p >Tarifa de Transporte será definida a la medida de cada Cliente. Esto dependiendo en distancia, volumen y complejidad de servicio, pero siempre siendo informado el Cliente con anterioridad, a fin de que pueda aceptar o negar el Servicio.</p>
            <p ><br/></p>
         </li>
         <li data-list-text="III.">
            <h1 >PLAN DE PLAZOS</h1>
            <p >Por medio de la Plataforma, el Cliente tendrá la opción de elegir libremente entre los Planes de Plazos descritos a continuación:</p>
            <p ><br/></p>
            <ol id="l38">
               <li data-list-text="(a)">
                  <p >El Plan Mensual implica que el Cliente se compromete a almacenar los Artículos de manera mensual, con una <u>duración mínima de un mes</u>.</p>
                  <p ><br/></p>
               </li>
               <li data-list-text="(b)">
                  <p >El Plan 6+ Meses implica que el Cliente acuerda mantener los Artículos almacenados por un <u>periodo</u> <u>mínimo de seis meses</u>. En cualquier momento, el Cliente puede solicitar la devolución de los Artículos; sin embargo, deberá liquidar los meses restantes basándose en el promedio de sus mensualidades de Tarifa de Almacenamiento.</p>
                  <p ><br/></p>
                  <p >Por ejemplo:</p>
                  <p >Mes 1: $1,000.00 MXN Mes 2: $1,000.00 MXN Mes 3: $500.00 MXN CLIENTE SALE</p>
                  <p >Mes 4: $833.33 MXN Mes 5: $833.33 MXN Mes 6: $833.33 MXN</p>
                  <p ><br/></p>
                  <p />
               </li>
               <li data-list-text="(c)">
                  <p >El Plan 12+ Meses implica que el Cliente se compromete a almacenar los Artículos <u>por un periodo mínimo</u> <u>de doce meses</u>. En cualquier momento, el Cliente puede solicitar la devolución de los Artículos, pero deberá liquidar los meses restantes según el promedio de las mensualidades de Tarifa de Almacenamiento.</p>
               </li>
            </ol>
         </li>
      </ol>
      <p >Por ejemplo:</p>
      <p ><br/></p>
      <p >Mes 1: $1,000.00 MXN Mes 2: $1,000.00 MXN Mes 3: $1,000.00 MXN Mes 4: $1,000.00 MXN Mes 5: $1,000.00 MXN Mes 6: $1,000.00 MXN Mes 7: $500.00 MXN Mes 8: $500.00 MXN</p>
      <p >CLIENTE SALE</p>
      <p >Mes 9: $875.00 MXN Mes 10: $875.00 MXN Mes 11: $875.00 MXN Mes 12: $875.00 MXN</p>
      <p ><br/></p>
      <p >Al respecto, el Cliente reconoce que una vez seleccionado un Plan de Plazos, el mismo no podrá modificarse en el futuro. En cualquier caso, el Cliente reconoce que, salvo notificación que realice el Cliente a Boxmi en cualquiera de los medios de comunicación establecidos en la Cláusula Octava del Contrato y antes de que termine la vigencia del Plan seleccionado, los Servicios serán renovados por el Plan Mensual descrito en el inciso (a) anterior, otorgando al Cliente el beneficio consistente en continuar con el pago de la tarifa conforme al Plan de Plazos que hubiere seleccionado originalmente.</p>
      </div>
    )
  }
}
